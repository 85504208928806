import React, { useState, useEffect, useRef } from "react";
import { doc, getDoc, getDocs, updateDoc, collection, setDoc } from "firebase/firestore";
import { db } from '../firebase/firestore';
import { useUser } from "../context/userContext";
import YouTube from "react-youtube";
import { useSwipeable } from 'react-swipeable';  
import { AiFillHeart } from "react-icons/ai"; // Importing the filled heart icon
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { MdOutlineSkipNext, MdOutlineSkipPrevious, MdThumbUp, MdThumbDown, MdVolumeOff, MdVolumeUp, MdStar } from "react-icons/md";

import './TGShorts.css'; // Import for custom animations

const TGShorts = () => {
  const { id, balance, setBalance, miningTotal, setMiningTotal, username, profilePicture } = useUser();

  const [videoIndex, setVideoIndex] = useState(0);
  const [videos, setVideos] = useState([]); // Dynamically fetched videos
  const [canClaim, setCanClaim] = useState(false);
  const [pointsEarned, setPointsEarned] = useState(0); // Dynamically set points from bonus
  const [claimModal, setClaimModal] = useState(false);
  const [congrats, setCongrats] = useState(false);
  const [watchedTime, setWatchedTime] = useState(0);
  const [timeLeft, setTimeLeft] = useState(10); // Default to 10 seconds, but dynamic per video
  const [liked, setLiked] = useState(null); // Can be true, false, or null
  const [likeCount, setLikeCount] = useState(0);
  const [dislikeCount, setDislikeCount] = useState(0);
  const [rewardedVideos, setRewardedVideos] = useState([]);
  const [isMuted, setIsMuted] = useState(true); // Mute state for video

  const playerRef = useRef(null); // Reference to YouTube player instance

  let intervalId = useRef(null);

  // Fetch video tasks dynamically from Firestore
  useEffect(() => {
    const fetchVideos = async () => {
      const querySnapshot = await getDocs(collection(db, 'youtubeTasks'));
      const tasksList = querySnapshot.docs.map(doc => doc.data());
      const shuffledVideos = shuffleArray(tasksList); // Shuffle the fetched videos
      setVideos(shuffledVideos);
    };

    fetchVideos();
  }, []);

  useEffect(() => {
    const fetchUserRewards = async () => {
      if (id) {
        const userRef = doc(db, 'telegramUsers', id.toString());
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setRewardedVideos(data.rewardedVideos || []);
        }
      }
    };
    fetchUserRewards();
  }, [id]);

  useEffect(() => {
    if (videos.length > 0) {
      const video = videos[videoIndex];
      const fetchLikesData = async () => {
        const videoRef = doc(db, 'videoLikes', video.link);
        const docSnap = await getDoc(videoRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setLikeCount(data.likes || 0);
          setDislikeCount(data.dislikes || 0);
        } else {
          setLikeCount(0);
          setDislikeCount(0);
        }
        setLiked(null);
        resetCountdown(video.duration); // Use video-specific duration
        setPointsEarned(video.bonus); // Set points from the bonus field
      };
      fetchLikesData();
    }
  }, [videoIndex, videos]);

  const isVideoRewarded = rewardedVideos.includes(videos[videoIndex]?.link);

  const handleVideoStateChange = (event) => {
    if (event.data === 1 && !isVideoRewarded) { 
      setCanClaim(false);
      startCountdown(videos[videoIndex]?.duration || 10); // Use video-specific duration
    } else if (event.data === 0) { 
      nextVideo(); 
    } else if (event.data === 2) { 
      stopCountdown();
    }
  };

  const startCountdown = (duration) => {
    stopCountdown(); // Clear any previous intervals
    intervalId.current = setInterval(() => {
      if (playerRef.current) {
        const currentTime = playerRef.current.getCurrentTime();
        setWatchedTime(currentTime);

        if (currentTime >= duration && !isVideoRewarded) {
          clearInterval(intervalId.current);
          setCanClaim(true);
          setTimeLeft(0);
        } else {
          setTimeLeft(Math.ceil(duration - currentTime));
        }
      }
    }, 1000);
  };

  const stopCountdown = () => {
    clearInterval(intervalId.current);
    setWatchedTime(0);
    setCanClaim(false);
  };

  const resetCountdown = (duration) => {
    setWatchedTime(0);
    setTimeLeft(duration); // Reset based on video-specific duration
  };

  const saveRewardStatus = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    const updatedVideos = [...rewardedVideos, videos[videoIndex]?.link];
    setRewardedVideos(updatedVideos); // Update local state immediately
    await updateDoc(userRef, {
      rewardedVideos: updatedVideos,
    });
  };

  const claimPoints = async () => {
    if (!id || isVideoRewarded || !canClaim) {
      console.error("Invalid user ID, video already rewarded, or not ready to claim.");
      return;
    }

    try {
      const userRef = doc(db, 'telegramUsers', id.toString());
      const newBalance = balance + pointsEarned;
      const newMiningTotal = miningTotal + pointsEarned;

      await updateDoc(userRef, {
        balance: newBalance,
        miningTotal: newMiningTotal
      });

      await saveRewardStatus();

      setClaimModal(true);
      setBalance(newBalance);
      setMiningTotal(newMiningTotal);
      setCongrats(true);
      setCanClaim(false);
      setTimeout(() => {
        setCongrats(false);
      }, 3000);
    } catch (error) {
      console.error("Error updating balance in Firestore:", error);
    }
  };

  const handleLike = async () => {
    const videoId = videos[videoIndex]?.link;
    const videoRef = doc(db, 'videoLikes', videoId);
    
    try {
      // First, check if the document exists
      const docSnap = await getDoc(videoRef);
      
      if (!docSnap.exists()) {
        // If it doesn't exist, create the document with initial values
        await setDoc(videoRef, { likes: 0, dislikes: 0 });
      }
      
      // Now proceed with the like operation
      if (liked === true) {
        setLiked(null);
        setLikeCount(likeCount - 1);
        await updateDoc(videoRef, { likes: likeCount - 1 });
      } else {
        if (liked === false) {
          setDislikeCount(dislikeCount - 1);
        }
        setLiked(true);
        setLikeCount(likeCount + 1);
        await updateDoc(videoRef, { likes: likeCount + 1, dislikes: dislikeCount });
      }
    } catch (error) {
      console.error("Error handling like:", error);
    }
  };
  
  const handleDislike = async () => {
    const videoId = videos[videoIndex]?.link;
    const videoRef = doc(db, 'videoLikes', videoId);
  
    try {
      // First, check if the document exists
      const docSnap = await getDoc(videoRef);
  
      if (!docSnap.exists()) {
        // If it doesn't exist, create the document with initial values
        await setDoc(videoRef, { likes: 0, dislikes: 0 });
      }
  
      // Now proceed with the dislike operation
      if (liked === false) {
        setLiked(null);
        setDislikeCount(dislikeCount - 1);
        await updateDoc(videoRef, { dislikes: dislikeCount - 1 });
      } else {
        if (liked === true) {
          setLikeCount(likeCount - 1);
        }
        setLiked(false);
        setDislikeCount(dislikeCount + 1);
        await updateDoc(videoRef, { likes: likeCount, dislikes: dislikeCount + 1 });
      }
    } catch (error) {
      console.error("Error handling dislike:", error);
    }
  };
  
  const resetStateForNewVideo = () => {
    stopCountdown();
    setLiked(null);
  };

  const nextVideo = () => {
    setVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
    resetStateForNewVideo();
    playerRef.current.loadVideoById(videos[(videoIndex + 1) % videos.length]?.link);
  };

  const previousVideo = () => {
    setVideoIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length);
    resetStateForNewVideo();
    playerRef.current.loadVideoById(videos[(videoIndex - 1 + videos.length) % videos.length]?.link);
  };

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => nextVideo(),
    onSwipedDown: () => previousVideo(),
    preventScrollOnSwipe: true,
  });

  const toggleMute = () => {
    setIsMuted(!isMuted);
    if (playerRef.current) {
      playerRef.current.isMuted() ? playerRef.current.unMute() : playerRef.current.mute();
    }
  };

  // Utility function to shuffle an array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  };

  return (
    <div {...swipeHandlers} className="w-full h-screen bg-gradient-to-r from-black to-black flex flex-col items-center justify-center relative">
      
      {/* Header */}
      <header className="w-full bg-gradient-to-r from-black to-purple-900 shadow-md fixed top-0 left-0 right-0 z-50 flex justify-between items-center px-2 py-2 header-tiny">
        <h1 className="text-lg font-bold text-white">Otters</h1>
        <div className="flex items-center space-x-2">
          <div className="flex items-center space-x-2">
            <img
              src={profilePicture || 'https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png'}
              alt={username}
              className="w-8 h-8 rounded-full shadow-lg"
            />
            <span className="font-semibold text-white text-sm">{username}</span>
          </div>
          <div className="text-sm text-white">
            <span className="block font-semibold">Balance</span>
            <span className="text-yellow-400 font-bold">{balance} points</span>
          </div>
        </div>
      </header>

      {/* Full-Screen Video */}
      <div className="w-full h-[calc(93vh-50px)] bg-black shadow-lg overflow-hidden relative video-container">
        {videos.length > 0 && (
          <YouTube
            videoId={videos[videoIndex]?.link}
            opts={{ width: '100%', height: '100%', playerVars: { autoplay: 1, mute: 1 } }}
            onReady={(event) => playerRef.current = event.target}
            onStateChange={handleVideoStateChange}
            className="w-full h-full"
          />
        )}

        {/* Countdown Timer */}
        <div className="absolute top-4 left-1/2 transform -translate-x-1/2 text-white text-md bg-gradient-to-r from-purple-600 to-black bg-opacity-90 py-2 px-4 rounded-full shadow-lg">
          {isVideoRewarded ? "Already Claimed!" : (canClaim ? "Claim Reward Now!" : `Wait ${timeLeft}s`)}
        </div>

        {/* Video Controls */}
        <div className="absolute bottom-4 right-4 flex flex-col space-y-4 items-center text-white z-10">
          <button onClick={previousVideo} className="bg-gray-700 text-white font-semibold p-3 rounded-full shadow-lg transition-transform transform hover:scale-105">
            <MdOutlineSkipPrevious size={24} />
          </button>
          <button onClick={handleLike} className={`bg-gray-700 text-white font-semibold p-3 rounded-full shadow-lg transition-transform transform hover:scale-105 ${liked === true ? 'opacity-50' : ''}`}>
            <MdThumbUp size={24} />
          </button>
          <span className="text-xs">{likeCount}</span>
          <button onClick={handleDislike} className={`bg-gray-700 text-white font-semibold p-3 rounded-full shadow-lg transition-transform transform hover:scale-105 ${liked === false ? 'opacity-50' : ''}`}>
            <MdThumbDown size={24} />
          </button>
          <span className="text-xs">{dislikeCount}</span>
          <button onClick={nextVideo} className="bg-gray-700 text-white font-semibold p-3 rounded-full shadow-lg transition-transform transform hover:scale-105">
            <MdOutlineSkipNext size={24} />
          </button>
          <button onClick={toggleMute} className="bg-gray-700 text-white font-semibold p-3 rounded-full shadow-lg transition-transform transform hover:scale-105">
            {isMuted ? <MdVolumeOff size={24} /> : <MdVolumeUp size={24} />}
          </button>
        </div>

        {/* Claim Reward Button */}
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
          {!isVideoRewarded && canClaim ? (
            <button
              onClick={claimPoints}
              className="bg-yellow-400 text-black font-bold py-3 px-6 rounded-lg flex items-center shadow-lg hover:bg-yellow-500 transition-transform transform hover:scale-105"
            >
              <MdStar className="mr-2" />
              Claim Reward
            </button>
          ) : (
            <button disabled className="bg-gray-700 text-white font-bold py-3 px-6 rounded-lg flex items-center shadow-lg">
              <MdStar className="mr-2" />
              {isVideoRewarded ? "Already Claimed" : "Wait..."}
            </button>
          )}
        </div>
      </div>

      {/* Claim Modal */}
      {claimModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="w-full max-w-md bg-gradient-to-r from-purple-800 to-black rounded-lg shadow-lg p-6 text-center transform transition-all duration-300 ease-out">
          <IoCheckmarkCircleSharp size={48} className="text-red-500 mx-auto mb-4" />
            <h2 className="text-3xl font-semibold text-white mb-2">Congratulations!</h2>
            <p className="text-lg font-bold text-yellow-300 mb-6">You've earned +{pointsEarned} points!</p>
            <button
              onClick={() => setClaimModal(false)}
              className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-lg transition-transform transform hover:scale-105"
            >
              Continue
            </button>
          </div>
        </div>
      )}

      {/* Congrats Pulse Animation */}
      {congrats && (
        <div className="fixed inset-0 flex justify-center items-center z-50">
          <div className="pulse-animation p-6 rounded-full shadow-lg">
            <AiFillHeart size={64} className="text-red-500 mx-auto mb-4" />
          </div>
        </div>
      )}

    </div>
  );
};

export default TGShorts;
