//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD_6gBtTp-3KbgR9MO9buHdn415OF1Lmwk",
  authDomain: "otters-c77b2.firebaseapp.com",
  projectId: "otters-c77b2",
  storageBucket: "otters-c77b2.firebasestorage.app",
  messagingSenderId: "713234628708",
  appId: "1:713234628708:web:55a66398f8b2fc4ab0df71",
  measurementId: "G-P1W8C0JN62",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
